* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
body::-webkit-scrollbar {
  background-color: black !important;
  width: 8px !important;
}

/* background of the scrollbar except button or resizer */
body::-webkit-scrollbar-track {
  background-color: black !important;
}

/* scrollbar itself */
body::-webkit-scrollbar-thumb {
  background-color: #babac0 !important;
  border-radius: 16px !important;
}
/* scrollbar itself */
body::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5 !important;
  border-radius: 16px !important;
}


/* set button(top and bottom of the scrollbar) */
body::-webkit-scrollbar-button {
  display:none !important;
}